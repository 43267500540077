export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const USERS_FETCHING = "USERS_FETCHING";
export const USERS_FAILED = "USERS_FAILED";
export const USERS_SUCCESS = "USERS_SUCCESS";

export const USERPROFILE_FETCHING = "USERPROFILE_FETCHING";
export const USERPROFILE_FAILED = "USERPROFILE_FAILED";
export const USERPROFILE_SUCCESS = "USERPROFILE_SUCCESS";

export const POSITIONS_FETCHING = "POSITIONS_FETCHING";
export const POSITIONS_FAILED = "POSITIONS_FAILED";
export const POSITIONS_SUCCESS = "POSITIONS_SUCCESS";
export const POSITIONSCOURSE_FETCHING = "POSITIONSCOURSE_FETCHING";
export const POSITIONSCOURSE_FAILED = "POSITIONSCOURSE_FAILED";
export const POSITIONSCOURSE_SUCCESS = "POSITIONSCOURSE_SUCCESS";

export const DEPARTMENT_FETCHING = "DEPARTMENT_FETCHING";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";
export const DEPARTMENT_FAILED = "DEPARTMENT_FAILED";

export const SECTION_FETCHING = "SECTION_FETCHING";
export const SECTION_SUCCESS = "SECTION_SUCCESS";
export const SECTION_FAILED = "SECTION_FAILED";

export const AFFILIATE_FETCHING = "AFFILIATE_FETCHING";
export const AFFILIATE_SUCCESS = "AFFILIATE_SUCCESS";
export const AFFILIATE_FAILED = "AFFILIATE_FAILED";
export const AFFILIATE_ORGANIZATION_FETCHING = "AFFILIATE_ORGANIZATION_FETCHING";
export const AFFILIATE_ORGANIZATION_SUCCESS = "AFFILIATE_ORGANIZATION_SUCCESS";
export const AFFILIATE_ORGANIZATION_FAILED = "AFFILIATE_ORGANIZATION_FAILED";

export const CLASSCOURSE_FETCHING = "CLASSCOURSE_FETCHING";
export const CLASSCOURSE_FAILED = "CLASSCOURSE_FAILED";
export const CLASSCOURSE_SUCCESS = "CLASSCOURSE_SUCCESS";

export const SKILL_FETCHING = "SKILL_FETCHING";
export const SKILL_FAILED = "SKILL_FAILED";
export const SKILL_SUCCESS = "SKILL_SUCCESS";
export const SKILLDATA_FETCHING = "SKILLDATA_FETCHING";
export const SKILLDATA_FAILED = "SKILLDATA_FAILED";
export const SKILLDATA_SUCCESS = "SKILLDATA_SUCCESS";
export const SKILLGROUP_FETCHING = "SKILLGROUP_FETCHING";
export const SKILLGROUP_FAILED = "SKILLGROUP_FAILED";
export const SKILLGROUP_SUCCESS = "SKILLGROUP_SUCCESS";
export const SKILLTYPE_FETCHING = "SKILLTYPE_FETCHING";
export const SKILLTYPE_FAILED = "SKILLTYPE_FAILED";
export const SKILLTYPE_SUCCESS = "SKILLTYPE_SUCCESS";
export const SKILLLEVEL_FETCHING = "SKILLLEVEL_FETCHING";
export const SKILLLEVEL_FAILED = "SKILLLEVEL_FAILED";
export const SKILLLEVEL_SUCCESS = "SKILLLEVEL_SUCCESS";
export const SKILLMYAPPOINT_FETCHING = "SKILLMYAPPOINT_FETCHING";
export const SKILLMYAPPOINT_FAILED = "SKILLMYAPPOINT_FAILED";
export const SKILLMYAPPOINT_SUCCESS = "SKILLMYAPPOINT_SUCCESS";
export const SKILLSETTING_FETCHING = "SKILLSETTING_FETCHING";
export const SKILLSETTING_FAILED = "SKILLSETTING_FAILED";
export const SKILLSETTING_SUCCESS = "SKILLSETTING_SUCCESS";

export const COMPETENCYLIST_FETCHING = "COMPETENCYLIST_FETCHING";
export const COMPETENCYLIST_FAILED = "COMPETENCYLIST_FAILED";
export const COMPETENCYLIST_SUCCESS = "COMPETENCYLIST_SUCCESS";
export const COMPETENCYPOSITION_FETCHING = "COMPETENCYPOSITION_FETCHING";
export const COMPETENCYPOSITION_FAILED = "COMPETENCYPOSITION_FAILED";
export const COMPETENCYPOSITION_SUCCESS = "COMPETENCYPOSITION_SUCCESS";
export const COMPETENCYLEVEL_FETCHING = "COMPETENCYLEVEL_FETCHING";
export const COMPETENCYLEVEL_FAILED = "COMPETENCYLEVEL_FAILED";
export const COMPETENCYLEVEL_SUCCESS = "COMPETENCYLEVEL_SUCCESS";
export const COMPETENCYEVALUATE_FETCHING = "COMPETENCYEVALUATE_FETCHING";
export const COMPETENCYEVALUATE_FAILED = "COMPETENCYEVALUATE_FAILED";
export const COMPETENCYEVALUATE_SUCCESS = "COMPETENCYEVALUATE_SUCCESS";
export const COMPETENCYSUGGEST_FETCHING = "COMPETENCYSUGGEST_FETCHING";
export const COMPETENCYSUGGEST_FAILED = "COMPETENCYSUGGEST_FAILED";
export const COMPETENCYSUGGEST_SUCCESS = "COMPETENCYSUGGEST_SUCCESS";
export const COMPETENCYEMPLOYEE_FETCHING = "COMPETENCYEMPLOYEE_FETCHING";
export const COMPETENCYEMPLOYEE_FAILED = "COMPETENCYEMPLOYEE_FAILED";
export const COMPETENCYEMPLOYEE_SUCCESS = "COMPETENCYEMPLOYEE_SUCCESS";

export const QUIZ_FETCHING = "QUIZ_FETCHING";
export const QUIZ_FAILED = "QUIZ_FAILED";
export const QUIZ_SUCCESS = "QUIZ_SUCCESS";
export const QUIZSTATUS_FETCHING = "QUIZSTATUS_FETCHING";
export const QUIZSTATUS_FAILED = "QUIZSTATUS_FAILED";
export const QUIZSTATUS_SUCCESS = "QUIZSTATUS_SUCCESS";
export const QUIZ_QUESTION_FETCHING = "QUIZ_QUESTION_FETCHING";
export const QUIZ_QUESTION_FAILED = "QUIZ_QUESTION_FAILED";
export const QUIZ_QUESTION_SUCCESS = "QUIZ_QUESTION_SUCCESS";

export const CERTIFICATE_FETCHING = "CERTIFICATE_FETCHING";
export const CERTIFICATE_FAILED = "CERTIFICATE_FAILED";
export const CERTIFICATE_SUCCESS = "CERTIFICATE_SUCCESS";
export const CERTIFICATIONEMP_FETCHING = "CERTIFICATIONEMP_FETCHING";
export const CERTIFICATIONEMP_FAILED = "CERTIFICATIONEMP_FAILED";
export const CERTIFICATIONEMP_SUCCESS = "CERTIFICATIONEMP_SUCCESS";

export const LICENSEEMP_FETCHING = "LICENSEEMP_FETCHING";
export const LICENSEEMP_FAILED = "LICENSEEMP_FAILED";
export const LICENSEEMP_SUCCESS = "LICENSEEMP_SUCCESS";
export const LICENSE_FETCHING = "LICENSE_FETCHING";
export const LICENSE_FAILED = "LICENSE_FAILED";
export const LICENSE_SUCCESS = "LICENSE_SUCCESS";

export const COURSELIST_FETCHING = "COURSELIST_FETCHING";
export const COURSELIST_FAILED = "COURSELIST_FAILED";
export const COURSELIST_SUCCESS = "COURSELIST_SUCCESS";
export const COURSESETTING_FETCHING = "COURSESETTING_FETCHING";
export const COURSESETTING_FAILED = "COURSESETTING_FAILED";
export const COURSESETTING_SUCCESS = "COURSESETTING_SUCCESS";

export const COURSEDATA_FETCHING = "COURSEDATA_FETCHING";
export const COURSEDATA_FAILED = "COURSEDATA_FAILED";
export const COURSEDATA_SUCCESS = "COURSEDATA_SUCCESS";

export const COURSECODE_FETCHING = "COURSECODE_FETCHING";
export const COURSECODE_FAILED = "COURSECODE_FAILED";
export const COURSECODE_SUCCESS = "COURSECODE_SUCCESS";

export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const CATEGORY_FAILED = "CATEGORY_FAILED";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";

export const TEACHER_FETCHING = "TEACHER_FETCHING";
export const TEACHER_FAILED = "TEACHER_FAILED";
export const TEACHER_SUCCESS = "TEACHER_SUCCESS";
export const TEACHERDATA_FETCHING = "TEACHERDATA_FETCHING";
export const TEACHERDATA_FAILED = "TEACHERDATA_FAILED";
export const TEACHERDATA_SUCCESS = "TEACHERDATA_SUCCESS";
export const ASSESSOR_FETCHING = "ASSESSOR_FETCHING";
export const ASSESSOR_FAILED = "ASSESSOR_FAILED";
export const ASSESSOR_SUCCESS = "ASSESSOR_SUCCESS";


export const ONLINETOPICS_FETCHING = "ONLINETOPICS_FETCHING";
export const ONLINETOPICS_FAILED = "ONLINETOPICS_FAILED";
export const ONLINETOPICS_SUCCESS = "ONLINETOPICS_SUCCESS";

export const ONJOBTRAININGLIST_FETCHING = "ONJOBTRAININGLIST_FETCHING";
export const ONJOBTRAININGLIST_FAILED = "ONJOBTRAININGLIST_FAILED";
export const ONJOBTRAININGLIST_SUCCESS = "ONJOBTRAININGLIST_SUCCESS";
export const ONJOBTRAININGSTATUS_FETCHING = "ONJOBTRAININGSTATUS_FETCHING";
export const ONJOBTRAININGSTATUS_FAILED = "ONJOBTRAININGSTATUS_FAILED";
export const ONJOBTRAININGSTATUS_SUCCESS = "ONJOBTRAININGSTATUS_SUCCESS";
export const ONJOBTRAININGDATA_FETCHING = "ONJOBTRAININGDATA_FETCHING";
export const ONJOBTRAININGDATA_FAILED = "ONJOBTRAININGDATA_FAILED";
export const ONJOBTRAININGDATA_SUCCESS = "ONJOBTRAININGDATA_SUCCESS";

export const ASSESSGAP_TARGETYEAR_FETCHING = "ASSESSGAP_TARGETYEAR_FETCHING";
export const ASSESSGAP_TARGETYEAR_FAILED = "ASSESSGAP_TARGETYEAR_FAILED";
export const ASSESSGAP_TARGETYEAR_SUCCESS = "ASSESSGAP_TARGETYEAR_SUCCESS";

export const EMPLOYEES_FETCHING = "EMPLOYEES_FETCHING";
export const EMPLOYEES_FAILED = "EMPLOYEES_FAILED";
export const EMPLOYEES_SUCCESS = "EMPLOYEES_SUCCESS";

export const EMPLOYEEDATA_FETCHING = "EMPLOYEEDATA_FETCHING";
export const EMPLOYEEDATA_FAILED = "EMPLOYEEDATA_FAILED";
export const EMPLOYEEDATA_SUCCESS = "EMPLOYEEDATA_SUCCESS";

export const EMPONLINECOURSES_FETCHING = "EMPONLINECOURSES_FETCHING";
export const EMPONLINECOURSES_FAILED = "EMPONLINECOURSES_FAILED";
export const EMPONLINECOURSES_SUCCESS = "EMPONLINECOURSES_SUCCESS";


export const VIDEO_FETCHING = "VIDEO_FETCHING";
export const VIDEO_FAILED = "VIDEO_FAILED";
export const VIDEO_SUCCESS = "VIDEO_SUCCESS";
export const EMPREGISTER_FETCHING = "EMPREGISTER_FETCHING";
export const EMPREGISTER_FAILED = "EMPREGISTER_FAILED";
export const EMPREGISTER_SUCCESS = "EMPREGISTER_SUCCESS";


export const APPROVELIST_FETCHING = "APPROVELIST_FETCHING";
export const APPROVELIST_SUCCESS = "APPROVELIST_SUCCESS";
export const APPROVELIST_FAILED = "APPROVELIST_FAILED";

export const ASSESSGAP_FETCHING = "ASSESSGAP_FETCHING";
export const ASSESSGAP_SUCCESS = "ASSESSGAP_SUCCESS";
export const ASSESSGAP_FAILED = "ASSESSGAP_FAILED";

export const TARGETYEAR_FETCHING = "TARGETYEAR_FETCHING";
export const TARGETYEAR_SUCCESS = "TARGETYEAR_SUCCESS";
export const TARGETYEAR_FAILED = "TARGETYEAR_FAILED";

export const DASHBOARDDATA_FETCHING = "DASHBOARDDATA_FETCHING";
export const DASHBOARDDATA_SUCCESS = "DASHBOARDDATA_SUCCESS";
export const DASHBOARDDATA_FAILED = "DASHBOARDDATA_FAILED";

export const POTENTIAL_FETCHING = "POTENTIAL_FETCHING";
export const POTENTIAL_SUCCESS = "POTENTIAL_SUCCESS";
export const POTENTIAL_FAILED = "POTENTIAL_FAILED";
